$(document).ready(function() {
     $('.accordion').click(function() {
                // Toggle active class on the clicked accordion and its panel
                $(this).toggleClass('active');
                $(this).next('.panel').toggleClass('active');
            });
     $('#openTocButton').click(function(event) {
         $('.toc').toggleClass('tocOpen');
     });
     $('.header__hamburger').click(function(event) {
         $(this).toggleClass('active');
         $('.main-nav').toggleClass('active');
     });
});